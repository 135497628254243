import { graphql, useStaticQuery } from 'gatsby';

import { ISymptomsTabContent, ISymptomTab } from '../model';

const useSymptomsQuery = (): ISymptomTab[] => {
  const { symptoms }: ISymptomsTabContent = useStaticQuery(graphql`
    query SymptomsTabs {
      symptoms: allUmbracoSymptoms {
        nodes {
          link
          content: symptomsHeader {
            properties {
              list {
                properties {
                  text
                }
              }
              image {
                fallbackUrl
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              svg: image {
                svg {
                  content
                }
              }
              imageAlt
            }
          }
        }
      }
    }
  `);

  return symptoms.nodes;
};

export default useSymptomsQuery;
