import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IPageLinkLinkProps } from './model';

const PageLink: FC<IPageLinkLinkProps> = ({
  link,
  ariaLabel,
  className,
  children,
  ...restAnchorProps
}) =>
  /^\/(?!\/)/.test(link) ? (
    <Link className={className} to={link} aria-label={ariaLabel}>
      {children}
    </Link>
  ) : (
    <a
      href={link}
      className={className}
      aria-label={ariaLabel}
      rel="noopener noreferrer"
      {...restAnchorProps}
    >
      {children}
    </a>
  );

export default PageLink;
