import React, { FC } from 'react';

import PageLink from 'components/common/PageLink';
import UniversalImage from 'components/common/UniversalImage';

import { IHubLinksProps } from 'components/HubContentSection/model.d';

const HubLinks: FC<IHubLinksProps> = ({ links }) => (
  <ul className="hub-content__links">
    {links.map(({ properties: { icon, iconAlt, link: [{ name, url, target }] } }) => (
      <li key={url} className="hub-content__link-item">
        <PageLink
          className="hub-content__link-item-link"
          link={url}
          ariaLabel={name}
          target={target}
        >
          <span className="hub-content__link-item-link-text">{name}</span>
          <UniversalImage img={icon} imageAlt={iconAlt} />
        </PageLink>
      </li>
    ))}
  </ul>
);

export default HubLinks;
