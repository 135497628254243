import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import PageLink from 'components/common/PageLink';

import { IHubArticleCardProps } from 'components/HubContentSection/model.d';

const HubArticleCard: FC<IHubArticleCardProps> = ({
  link: [{ url, target }],
  title,
  description,
  image,
  imageAlt,
  tags,
}) => (
  <PageLink className="hub-content__item-container" link={url} ariaLabel={title} target={target}>
    <UniversalImage wrapperClassName="hub-content__item-image" img={image} imageAlt={imageAlt} />
    <div className="hub-content__item-text">
      <div>
        <DangerouslySetInnerHtml className="hub-content__item-text-title" html={title} />
        <DangerouslySetInnerHtml
          className="hub-content__item-text-description"
          html={description}
        />
      </div>
      <ul className="hub-content__item-tags">
        {tags.map(({ value }) => (
          <li key={value} className="hub-content__item-tag">
            {value}
          </li>
        ))}
      </ul>
    </div>
  </PageLink>
);

export default HubArticleCard;
