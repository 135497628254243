import React, { FC } from 'react';
import classnames from 'classnames';

import PageLink from 'components/common/PageLink';
import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IHubRecommendedProps } from 'components/HubContentSection/model.d';

const HubRecommended: FC<IHubRecommendedProps> = ({ title, features, recommendedProduct }) => {
  const {
    label,
    labelBgColor,
    image,
    imageAlt,
    title: productName,
    description,
    link: [{ name, url, target }],
    linkBgColor,
  } = recommendedProduct[0].properties;

  return (
    <div className="hub-content__recommended">
      <DangerouslySetInnerHtml className="hub-content__recommended-title" html={title} />
      <ul className="hub-content__recommended-features">
        {features.map(({ properties: { text, icon, bgColor } }) => (
          <li
            key={text}
            className={classnames('hub-content__recommended-feature', {
              [`${bgColor?.[0]?.properties?.colorPicker?.label}-default-bg`]: bgColor?.[0]
                ?.properties?.colorPicker?.label,
            })}
          >
            <UniversalImage img={icon} wrapperClassName="hub-content__recommended-feature-image" />
            <DangerouslySetInnerHtml
              className="hub-content__recommended-feature-title"
              html={text}
            />
          </li>
        ))}
      </ul>

      <div className="hub-content__recommended-product">
        <span
          className={classnames('hub-content__recommended-product-label', {
            [`${labelBgColor?.[0]?.properties?.colorPicker?.label}-default-bg`]: labelBgColor?.[0]
              ?.properties?.colorPicker?.label,
          })}
        >
          {label}
        </span>
        <UniversalImage
          wrapperClassName="hub-content__recommended-product-image"
          img={image}
          imageAlt={imageAlt}
          objectFitData={{ objectFit: 'contain' }}
        />

        <div className="hub-content__recommended-product-text">
          <DangerouslySetInnerHtml
            className="hub-content__recommended-product-title"
            html={productName}
          />
          <DangerouslySetInnerHtml
            className="hub-content__recommended-product-description"
            html={description}
          />
          <PageLink
            link={url}
            target={target}
            ariaLabel={name}
            className={classnames('hub-content__recommended-product-link', {
              [`${linkBgColor?.[0]?.properties?.colorPicker?.label}-default-bg`]: linkBgColor?.[0]
                ?.properties?.colorPicker?.label,
            })}
          >
            {name}
          </PageLink>
        </div>
      </div>
    </div>
  );
};

export default HubRecommended;
