import { ISymptomTab } from '../model';

function getSymptomsByPaths(
  tabsLink: HubSymptomsTabs.IHubSymptomsTabsContent['tabsLink'],
  symptoms: ISymptomTab[]
) {
  const symptomsMap: Map<string, ISymptomTab> = new Map();

  tabsLink.forEach(({ url }) => {
    const symptom = symptoms.find(({ link }) => link === url);
    if (symptom) symptomsMap.set(url, symptom);
  });

  return symptomsMap;
}

export default getSymptomsByPaths;
