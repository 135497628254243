import React from 'react';

import ArticleSubNav from 'components/ArticleSubNav';
import PainBanner from 'components/PainBanner';
import Facts from 'components/Facts';
import TextWithImage from 'components/TextWithImage';
import Reports from 'components/Reports';
import Pledge from 'components/Pledge';
import ReadReportSection from 'components/ReadReportSection';
import TextSection from 'components/TextSection';
import Timeline from 'components/Timeline';
import PainBannerExtendTitle from 'components/PainBannerExtendTitle';
import PainVideoSection from 'components/PainVideoSection';
import VideoIframeSection from 'components/VideoIframeSection/VideoIframeSection';
import RteSection from 'components/RteSection/RteSection';
import Biases from 'components/Biases';
import Evidence from 'components/Evidence';
import Prevalence from 'components/Prevalence/Prevalence';
import Survey from 'components/Survey/Survey';
import CaseStudy from 'components/CaseStudy/CaseStudy';
import Podcast from 'components/Podcast/Podcast';
import Expert from 'components/Expert/Expert';
import Partnerships from 'components/Partnerships/Partnerships';
import News from 'components/News/News';
import RelatedPages from 'components/RelatedPages/RelatedPages';
import RelatedInfo from 'components/RelatedInfo/RelatedInfo';
import HCPBanner from 'components/HCPBanner';
import AnchorSubmenu from 'components/AnchorSubmenu';
import PainPassBanner from 'components/PainPassBanner/PainPassBanner';
import RememberSection from 'components/RememberSection';
import ScaleSection from 'components/ScaleSection/ScaleSection';
import Banner from 'components/Banner';
import HubContentSection from 'components/HubContentSection';
import { parseBanner } from 'components/Banner/parsers';
import HubRelatedPages from 'components/HubRelatedPages';
import HubSymptomsTabs from 'components/HubSymptomsTabs';

import { formatAnchor } from 'utils/helpers';
import { parseNFButton } from 'components/common/NFButton/parsers';

const bodySections: AppPageDataTypes.TBodyElements = {
  'Pain Banner': ({ title, bannerImage, imageAlt }, keyId) => (
    <PainBanner key={keyId} title={title} image={bannerImage} imageAlt={imageAlt} />
  ),
  'Article Anchor Navigation': ({ title, description, sectionAnchors, bulletColor }, keyId) => (
    <ArticleSubNav
      key={keyId}
      colWidth={{ md: 11 }}
      title={title}
      description={description}
      sections={sectionAnchors}
      bulletColor={bulletColor[0].properties.colorPicker?.label || 'red'}
    />
  ),
  'Facts Section': (
    { title, description, sectionBg, sectionTheme, facts, sectionAnchor, anchorText, fileLink },
    keyId
  ) => (
    <Facts
      key={keyId}
      title={title}
      description={description}
      facts={facts}
      anchorText={anchorText}
      fileLink={fileLink}
      sectionBg={sectionBg}
      sectionTheme={sectionTheme}
      sectionAnchor={formatAnchor(sectionAnchor)}
    />
  ),
  'Reports Section': (
    {
      title,
      description,
      reports,
      sectionAnchor,
      additionalAnchor,
      sectionBackground,
      sectionTheme,
      showCarouselOnMobile,
      shareBlock,
      isActiveWithReturning,
    },
    keyId
  ) => (
    <Reports
      key={keyId}
      title={title}
      description={description}
      reports={reports}
      sectionAnchor={formatAnchor(sectionAnchor)}
      sectionBackground={sectionBackground}
      sectionTheme={sectionTheme}
      showCarouselOnMobile={showCarouselOnMobile}
      shareBlock={shareBlock}
      additionalAnchor={formatAnchor(additionalAnchor)}
      isActiveWithReturning={isActiveWithReturning}
    />
  ),
  'Text with Image Section': ({ content, sectionAnchor }, keyId) => (
    <TextWithImage key={keyId} content={content} sectionAnchor={formatAnchor(sectionAnchor)} />
  ),
  'Text Item': ({ text }, keyId) => <TextSection key={keyId} text={text} />,
  'Pledge Section': (
    {
      title,
      description,
      items,
      sectionAnchor,
      sectionBackground,
      sectionTheme,
      showCarouselOnMobile,
    },
    keyId
  ) => (
    <Pledge
      key={keyId}
      title={title}
      description={description}
      items={items}
      sectionAnchor={formatAnchor(sectionAnchor)}
      sectionBackground={sectionBackground}
      sectionTheme={sectionTheme}
      showCarouselOnMobile={showCarouselOnMobile}
    />
  ),
  'Read Report Section': (
    {
      title,
      rteTitle,
      description,
      sectionAnchor,
      image,
      imageAlt,
      imageMobile,
      imageMobileAlt,
      imageDescription,
      imageBackground,
      anchorText,
      link,
      fileLink,
      icon,
      sectionBackground,
      sectionTheme,
      note,
      words,
      thingsList,
    },
    keyId
  ) => (
    <ReadReportSection
      key={keyId}
      title={title}
      rteTitle={rteTitle}
      description={description}
      sectionAnchor={formatAnchor(sectionAnchor)}
      image={image}
      imageAlt={imageAlt}
      imageMobile={imageMobile}
      imageMobileAlt={imageMobileAlt}
      imageDescription={imageDescription}
      imageBackground={imageBackground}
      anchorText={anchorText}
      link={link}
      fileLink={fileLink}
      icon={icon}
      sectionTheme={sectionTheme}
      sectionBackground={sectionBackground}
      note={note}
      words={words}
      thingsList={thingsList}
    />
  ),
  'Timeline Section': (
    {
      title,
      pledge,
      subtitle,
      description,
      timelineItems,
      sectionAnchor,
      sectionBackground,
      sectionTheme,
    },
    keyId
  ) => (
    <Timeline
      key={keyId}
      title={title}
      pledge={pledge}
      subtitle={subtitle}
      description={description}
      timelineItems={timelineItems}
      sectionAnchor={formatAnchor(sectionAnchor)}
      sectionBackground={sectionBackground}
      sectionTheme={sectionTheme}
    />
  ),
  'Pain Banner Extend Title': (
    { title, header, headerSmall, image, imageMobile, imageAlt, buttonLink, text, sectionTheme },
    keyId
  ) => (
    <PainBannerExtendTitle
      key={keyId}
      title={title}
      header={header}
      headerSmall={headerSmall}
      image={image}
      imageMobile={imageMobile}
      imageAlt={imageAlt}
      buttonLink={buttonLink?.[0]?.properties ? parseNFButton(buttonLink[0].properties) : undefined}
      text={text}
      sectionTheme={sectionTheme}
    />
  ),
  'Pain Video Section': ({ videoBlock, section }, keyId) => (
    <PainVideoSection videoBlock={videoBlock} section={section} key={keyId} />
  ),
  'Video Iframe Section': (
    {
      title,
      subtitle,
      description,
      videoIframe,
      readMoreButton,
      readMoreButtonAriaLabel,
      sectionTheme,
      sectionAnchor,
    },
    keyId
  ) => (
    <VideoIframeSection
      key={keyId}
      title={title}
      videoIframe={videoIframe}
      subtitle={subtitle}
      description={description}
      readMoreButton={readMoreButton}
      readMoreButtonAriaLabel={readMoreButtonAriaLabel}
      sectionTheme={sectionTheme}
      sectionAnchor={formatAnchor(sectionAnchor)}
    />
  ),
  'Rte Section': ({ text, sectionTheme, sectionBackground }, keyId) => (
    <RteSection
      key={keyId}
      text={text}
      sectionTheme={sectionTheme}
      sectionBackground={sectionBackground}
    />
  ),
  'Bias Section': ({ title, biases, sectionBackground }, keyId) => (
    <Biases key={keyId} title={title} biases={biases} sectionBackground={sectionBackground} />
  ),
  'Evidence Section': (
    { title, description, evidenceDescription, evidenceImage, alt, sectionBackground },
    keyId
  ) => (
    <Evidence
      key={keyId}
      title={title}
      description={description}
      evidenceDescription={evidenceDescription}
      image={evidenceImage}
      alt={alt}
      sectionBackground={sectionBackground}
    />
  ),
  'Prevalence Section': (
    { title, mobileImage, desktopImage, alt, indicators, prevalences },
    keyId
  ) => (
    <Prevalence
      key={keyId}
      title={title}
      mobileImage={mobileImage}
      desktopImage={desktopImage}
      alt={alt}
      indicators={indicators}
      prevalences={prevalences}
    />
  ),
  'Survey Section': ({ title, description, surveyTitle, surveyQuestion, surveyResults }, keyId) => (
    <Survey
      key={keyId}
      title={title}
      description={description}
      surveyTitle={surveyTitle}
      surveyQuestion={surveyQuestion}
      surveyResults={surveyResults}
    />
  ),
  'Case Studies Section': ({ title, description, cases, sectionBackground }, keyId) => (
    <CaseStudy
      key={keyId}
      title={title}
      description={description}
      cases={cases}
      sectionBackground={sectionBackground}
    />
  ),
  'Podcast Section': (
    { title, description, cta, ctaAriaLabel, image, alt, sectionBackground },
    keyId
  ) => (
    <Podcast
      key={keyId}
      title={title}
      description={description}
      cta={cta}
      ctaAriaLabel={ctaAriaLabel}
      image={image}
      alt={alt}
      sectionBackground={sectionBackground}
    />
  ),
  'Expert Section': (
    { title, quote, description, expertImage, alt, expertInfo, details, sectionBackground },
    keyId
  ) => (
    <Expert
      key={keyId}
      title={title}
      quote={quote}
      description={description}
      image={expertImage}
      alt={alt}
      expertInfo={expertInfo}
      details={details}
      sectionBackground={sectionBackground}
    />
  ),
  'Partnerships Section': ({ title, description, partnershipsImage, alt }, keyId) => (
    <Partnerships
      key={keyId}
      title={title}
      description={description}
      image={partnershipsImage}
      alt={alt}
    />
  ),
  'News Section': ({ newsBlock, assetsBlock, sectionBackground }, keyId) => (
    <News
      key={keyId}
      newsBlock={newsBlock}
      assetsBlock={assetsBlock}
      sectionBackground={sectionBackground}
    />
  ),
  'Related Pages Section': (
    { pages, sectionBackground, isCarouselMode, ariaLabelPrev, ariaLabelNext },
    keyId
  ) => (
    <RelatedPages
      key={keyId}
      pages={pages}
      isCarouselMode={isCarouselMode}
      sectionBackground={sectionBackground}
      ariaLabelPrev={ariaLabelPrev}
      ariaLabelNext={ariaLabelNext}
    />
  ),
  'Related Info Section': ({ title, info }, keyId) => (
    <RelatedInfo key={keyId} title={title} info={info} />
  ),
  'HCP Banner': (
    { title, desktopImg, mobileImg, alt, breadcrumbs, withBreadcrumbs, sectionTheme },
    keyId
  ) => (
    <HCPBanner
      key={keyId}
      title={title}
      image={desktopImg}
      mobileImage={mobileImg}
      alt={alt}
      breadcrumbs={breadcrumbs}
      withBreadcrumbs={withBreadcrumbs}
      sectionTheme={sectionTheme}
    />
  ),
  'Anchor Submenu': ({ sectionAnchors, sectionBg, sectionTheme }, keyId) => (
    <AnchorSubmenu
      key={keyId}
      sectionBg={sectionBg}
      sections={sectionAnchors}
      sectionTheme={sectionTheme}
    />
  ),
  'Pain Pass Banner': (
    {
      title,
      cta,
      painPassBannerImage,
      painPassBannerImageMobile,
      backgroundImage,
      imageAltBackground,
      mobileBackgroundImage,
      withAnchorNavigation,
      imageAlt,
      sectionAnchors,
    },
    keyId
  ) => (
    <PainPassBanner
      key={keyId}
      title={title}
      image={painPassBannerImage}
      imageAlt={imageAlt}
      imageMobile={painPassBannerImageMobile}
      cta={cta}
      backgroundImage={backgroundImage}
      imageAltBackground={imageAltBackground}
      mobileBackgroundImage={mobileBackgroundImage}
      sections={sectionAnchors}
      withAnchorNavigation={withAnchorNavigation}
    />
  ),
  'Remember Section': ({ sectionAnchor, title, description, rememberItems }, keyId) => (
    <RememberSection
      key={keyId}
      sectionAnchor={formatAnchor(sectionAnchor)}
      title={title}
      description={description}
      rememberItems={rememberItems}
    />
  ),
  'Scale Section': ({ sectionAnchor, title, description, sectionBg, scale }, keyId) => (
    <ScaleSection
      key={keyId}
      sectionAnchor={formatAnchor(sectionAnchor)}
      title={title}
      description={description}
      sectionBg={sectionBg}
      scale={scale}
    />
  ),
  'Page Banner': ({ breadcrumbs, sectionAnchors, ...restProperties }, keyId) => (
    <Banner
      key={keyId}
      {...parseBanner(restProperties as PageBanner.IUmbracoPageBannerMainProperties)}
      breadcrumbs={breadcrumbs}
      sectionAnchors={sectionAnchors}
    />
  ),
  'Hub Content Section': (
    {
      sectionAnchor,
      title,
      description,
      links,
      hubItems,
      hubRecommended,
      bottomLink,
      sectionBg,
      sectionTheme,
    },
    keyId
  ) => (
    <HubContentSection
      key={keyId}
      sectionAnchor={formatAnchor(sectionAnchor)}
      title={title}
      description={description}
      links={links}
      bottomLink={bottomLink}
      hubItems={hubItems}
      sectionBg={sectionBg}
      sectionTheme={sectionTheme}
      hubRecommended={hubRecommended}
    />
  ),
  'Hub Related Pages': ({ title, relatedPages }, keyId) => (
    <HubRelatedPages key={keyId} title={title} relatedPages={relatedPages} />
  ),
  'Hub Symptoms Tabs': (
    { title, description, tabsLink, tabCtaLabel, sectionAnchor, sectionBg },
    keyId
  ) => (
    <HubSymptomsTabs
      key={keyId}
      title={title}
      description={description}
      tabsLink={tabsLink}
      tabCtaLabel={tabCtaLabel}
      sectionAnchor={formatAnchor(sectionAnchor)}
      sectionBg={sectionBg}
    />
  ),
};

export default bodySections;
