import React, { FC, useState, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import NFButton from 'components/common/NFButton';
import AnchorSubmenu from 'components/AnchorSubmenu';

import { scrollToTarget } from 'utils/browser';

import { IPainPassBannerProps } from './model';

import './PainPassBanner.scss';

const PainPassBanner: FC<IPainPassBannerProps> = ({
  title,
  cta,
  image,
  imageAlt,
  imageMobile,
  imageMobileAlt,
  backgroundImage,
  imageAltBackground,
  mobileBackgroundImage,
  withAnchorNavigation,
  sections,
}) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const { name, url, target, queryString } = cta?.[0] || {};

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 992px)');

    function updateIsDesktop() {
      setIsDesktop(mediaQuery.matches);
    }
    updateIsDesktop();
    mediaQuery.addListener(updateIsDesktop);

    return () => mediaQuery.removeListener(updateIsDesktop);
  }, []);

  const scrollCallback = useCallback(() => {
    if (typeof queryString !== 'string') return;
    const anchor = queryString.replace(/[?#]/g, '');
    scrollToTarget(anchor);
  }, [queryString]);

  return (
    <section className="pain-pass">
      <div className="pain-pass__container">
        <div className="pain-pass__content-holder">
          {imageMobile ? (
            <UniversalImage
              wrapperClassName="pain-pass__image pain-pass__image--mobile"
              img={isDesktop ? image : imageMobile}
              imageAlt={isDesktop ? imageAlt : imageMobileAlt}
            />
          ) : (
            <UniversalImage wrapperClassName="pain-pass__image" img={image} imageAlt={imageAlt} />
          )}

          <DangerouslySetInnerHtml className="pain-pass__title" html={title} />
          <NFButton
            className="pain-pass__cta"
            btnText={name}
            link={url}
            target={target}
            onClick={scrollCallback}
            customElement={
              <span
                aria-hidden="true"
                className="nf-icon-custom icon-arrow_forward_ios pain-pass__icon"
              />
            }
          />
        </div>
        {withAnchorNavigation ? <AnchorSubmenu sections={sections} /> : null}
      </div>
      <UniversalImage
        wrapperClassName="pain-pass__background"
        img={isDesktop ? backgroundImage : mobileBackgroundImage}
        imageAlt={imageAltBackground}
        objectFitData={{ objectPosition: '100% 50%' }}
      />
    </section>
  );
};

export const query = graphql`
  fragment FragmentPainPassBanner on IPainPassBanner {
    title
    cta {
      queryString
      url
      target
      name
    }
    imageAltBackground
    withAnchorNavigation
    painPassBannerImage: image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 560, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    painPassBannerImageMobile: imageMobile {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 560, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    mobileBackgroundImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 992, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    backgroundImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default PainPassBanner;
