import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import PageLink from 'components/common/PageLink';
import Video from 'components/common/Video';

import { IHubVideoCardProps } from 'components/HubContentSection/model.d';

const HubVideoCard: FC<IHubVideoCardProps> = ({
  link: [{ url, target }],
  title,
  description,
  image,
  imageAlt,
  tags,
  playIcon,
  playBtnAriaLabel,
  videoLink,
}) => (
  <div className="hub-content__item-container">
    <div className="hub-content__item-video--wrapper">
      <Video
        btnPlay={playIcon.svg.content}
        previewImg={{ featuredImage: image, imgAlt: imageAlt }}
        video={videoLink}
        btnPlayAriaLabel={playBtnAriaLabel}
      />
    </div>

    <div className="hub-content__item-text">
      <div>
        <PageLink
          link={url}
          ariaLabel={title}
          target={target}
          className="hub-content__item-text-link"
        >
          <DangerouslySetInnerHtml className="hub-content__item-text-title" html={title} />
        </PageLink>
        <DangerouslySetInnerHtml
          className="hub-content__item-text-description"
          html={description}
        />
      </div>

      <ul className="hub-content__item-tags">
        {tags.map(({ value }) => (
          <li key={value} className="hub-content__item-tag">
            {value}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default HubVideoCard;
