import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import PageLink from 'components/common/PageLink';

import { IHubRelatedPagesProps } from './model';

import './HubRelatedPages.scss';

const HubRelatedPages: FC<IHubRelatedPagesProps> = ({ title, relatedPages }) => (
  <section className="hub-related-pages">
    <div className="hub-related-pages__container">
      <DangerouslySetInnerHtml className="hub-related-pages__title" html={title} />
      <ul
        className={classnames('hub-related-pages__list', {
          'hub-related-pages__list--one-row': relatedPages.length === 3,
        })}
      >
        {relatedPages.map(({ properties: { image, imageAlt, link: [{ name, url, target }] } }) => (
          <li key={url} className="hub-related-pages__item">
            <PageLink className="hub-related-pages__item-link" link={url} target={target}>
              <span className="hub-related-pages__item-link-name">{name}</span>
              <UniversalImage
                wrapperClassName="hub-related-pages__item-image"
                img={image}
                imageAlt={imageAlt}
              />
            </PageLink>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export const query = graphql`
  fragment FragmentHubRelatedPages on IHubRelatedPages {
    title
    relatedPages {
      properties {
        image {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 520, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        imageAlt
        link {
          name
          url
          target
        }
      }
    }
  }
`;

export default HubRelatedPages;
