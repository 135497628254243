import React, { FC, useState, useMemo } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import Button from 'components/common/Button';
import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import getSymptomsByPaths from './utils';
import useSymptomsQuery from './hooks/useSymptomsQuery';
import { IHubSymptomsTabsProps } from './model';

import './HubSymptomsTabs.scss';

const HubSymptomsTabs: FC<IHubSymptomsTabsProps> = ({
  sectionAnchor,
  title,
  description,
  tabsLink,
  tabCtaLabel,
  sectionBg,
}) => {
  const [activeTab, setActiveTab] = useState(tabsLink[0].url);
  const symptoms = useSymptomsQuery();
  const symptomsTabsMap = useMemo(() => getSymptomsByPaths(tabsLink, symptoms), []);

  const { link, content } = symptomsTabsMap.get(activeTab) || {};
  const { image, svg, imageAlt, list } = content?.[0]?.properties || {};

  return (
    <section
      id={sectionAnchor}
      className={classnames('hub-symptoms-tabs', {
        [`${sectionBg?.[0]?.properties?.colorPicker?.label}-default-bg`]: sectionBg?.[0]?.properties
          ?.colorPicker?.label,
      })}
    >
      <div className="hub-symptoms-tabs__container">
        <DangerouslySetInnerHtml className="hub-symptoms-tabs__title" html={title} />
        <DangerouslySetInnerHtml className="hub-symptoms-tabs__description" html={description} />
        <ul className="hub-symptoms-tabs__tabs">
          {tabsLink.map(({ url, name }) => (
            <li key={url} className="hub-symptoms-tabs__tab">
              <button
                type="button"
                className={classnames('hub-symptoms-tabs__btn', {
                  'hub-symptoms-tabs__btn--active': activeTab === url,
                })}
                onClick={() => setActiveTab(url)}
              >
                {name}
              </button>
            </li>
          ))}
        </ul>
        {list?.length && (image?.gatsbyImage?.childImageSharp || svg?.svg?.content) ? (
          <div className="hub-symptoms-tabs__content">
            <div className="hub-symptoms-tabs__content-image">
              {image?.gatsbyImage?.childImageSharp ? (
                <GatsbyImage fluid={image.gatsbyImage.childImageSharp.fluid} alt={imageAlt} />
              ) : (
                <DangerouslySetInnerHtml html={svg?.svg?.content} />
              )}
            </div>

            <ul className="hub-symptoms-tabs__content-list">
              {list.map(({ properties: { text } }) => (
                <li key={text} className="hub-symptoms-tabs__content-list-item">
                  {text}
                </li>
              ))}
            </ul>

            <Button className="hub-symptoms-tabs__content-link" link={link} ariaLabel={tabCtaLabel}>
              {tabCtaLabel}
            </Button>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentHubSymptomsTabs on IHubSymptomsTabs {
    sectionAnchor
    title
    description
    tabsLink {
      url
      name
    }
    tabCtaLabel
    sectionBg {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;

export default HubSymptomsTabs;
